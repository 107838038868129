.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: url("./BackgroundOnly2.jpg");
  background-size: 1700px auto; 
  background-position: center;
  background-color: #232323;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  position: relative;
  /* padding-top: -20px; */
}

.App-link {
  color: #61dafb;
}

.transparent-blur-bigtitle{
  font-family: 'Jost Medium';
  color: rgba(255, 255, 255, 0.7); 
  font-size: 100pt;
  letter-spacing: 20px;
}

.main-subtitle{
  font-family: 'Jost Book';
  color: rgba(255, 255, 255); 
  font-size: 20pt;
  letter-spacing: 5px;
  margin-top:-100px;
}

.main-subtitle-email {
  font-family: 'Jost Book';
  color: rgba(255, 255, 255); 
  font-size: 20pt;
  letter-spacing: 5px;
  position: absolute;
  bottom: 20px; /* Adjust to position email near the bottom */
  width: 100%;
  text-align: center;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
